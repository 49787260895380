<template>
  <div class="img_box">
    <img src="@/assets/close_icon.png" @click="closeDialog" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
<style lang="scss" scoped>
.img_box {
  position: fixed;
  right: 32px;
  top: 32px;
  img {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 768px) {
  .img_box {
    position: fixed;
    width: 100%;
    background-color: white;
    top: 0;
    right: 0;
    text-align: right;
    padding-right: 16px;
    padding-top: 10px;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
</style>