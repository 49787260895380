<template>
  <div class="main" style="padding:0;">
    <div :style="{height: $store.state.changeHeight + 'px'}"></div>
    <div class="main-head flex-space-between" :class="{'opacity_3': ticketsnum == 0}">
      <span class="boldFont">Subtotal: </span>
      <div class="totalMoney-box">
        <span class="boldFont">¥{{ subtotal || 0 | abs}}</span>
        <!-- <span class="totalMoney boldFont"></span> -->
        <i @click="toshopCart()" class="iconfont icon-gouwuche-copy-copy" style="position: relative;right:-14px;font-size:30px;width:46px;">
          <span class="ticketNum">{{ ticketsnum }}</span>
        </i>
      </div>
    </div>
    <div class="main-center">
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane label="TICKETS" name="1"></el-tab-pane>
        <el-tab-pane label="EXPRESS PASSES" name="2"></el-tab-pane>
        <el-tab-pane label="EXTRAS" name="3"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activetab: {
      type: String,
      default: '1'
    },
    subtotal: {
      type: Number,
      default: 0
    },
    ticketsnum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeTab: ''
    }
  },
  mounted() {
    console.log('总价',this.subtotal);
    this.activeTab = this.activetab
  },
  methods: {
    handleClick() {
      var options = {
        '1': () => { this.$router.push({ path: '/' }) },
        '2': () => { this.$router.push({ path: '/expressPass' }) },
        '3': () => { this.$router.push({ path: '/options' }) }
      }
      options[this.activeTab]()
    },
    toshopCart() {
      // this.$router.push({ path: '/shopCart' })
      this.$emit('toShopCartFromHeadTab')
    },
    Continue() {
      if (this.activetab === '1') {
        this.$router.push({ path: '/expressPass' })
      } else if (this.activetab === '2') {
        this.$router.push({ path: '/options' })
      } else if (this.activetab === '3') {
        this.$router.push({ path: '/shopCart' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  display: none;
  width: 100px;
  position: fixed;
  z-index: 1;
  background-color: white;
  .main-head {
    padding: 16px 23px;
    align-items: center;
  }
}
.totalMoney-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  .totalMoney {
    margin-right: 50px;
  }
}
.ticketNum {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  background-color: #163b75;
  color: white;
  text-align: center;
  font-size: 12px;
  top: 0;
  right: 15px;
}
@media (max-width: 780px) {
  .main {
    display: inline-block;
    .main-head {
      padding: 7px 23px;
    }
  }
}
::v-deep .el-tabs__item {
  font-weight: 700;
  padding: 0 8px;
  font-size: 12px;
}
::v-deep .el-icon-shopping-cart-2:before {
  font-size: 30px;
}
::v-deep .el-tabs__item.is-active {
  color: black;
  font-weight: 700;
}
::v-deep .el-tabs__item {
  color: #0959c2;
  line-height: 51px;
}
::v-deep .el-tabs__header {
  background-color: #f9f9f9;
  border-top: 1px solid #dbdbdb;
  padding-left: 0;
}
::v-deep .el-tabs__nav-scroll {
  padding: 0 12px;
}
::v-deep .el-tabs__active-bar {
  background-color: rgb(3, 108, 226);
  height: 4px;
}
::v-deep .el-tabs__nav {
  height: 51px;
}
.opacity_3 {
  opacity: 0.3;
}
</style>