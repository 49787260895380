export const descriptions = [
  {
    productId: 1,
    description: "Shorten your wait time for The Flying Dinosaur and other trending attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at seven different attractions!"
  },
  {
    productId: 2,
    description: "Shorten your wait time for SING on Tour and other popular attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 3,
    description: "Shorten your wait time for The Flying Dinosaur and other popular attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 4,
    description: "Shorten your wait time for Hollywood Dream - The Ride: Backdrop and other popular attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 5,
    description: "Shorten your wait time for Space Fantasy - The Ride and other popular attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 6,
    description: "Shorten your wait time for Despicable Me: Minion Mayhem and other popular attractions like Mario Kart: Koopa's Challenge and SING on Tour! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 7,
    description: "Shorten your wait time for Despicable Me: Minion Mayhem and other popular attractions like Yoshi's Adventure and SING on Tour! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 8,
    description: "Shorten your wait time for The Flying Dinosaur and other popular attractions like Mario Kart: Koopa's Challenge and Harry Potter and the Forbidden Journey™! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 9,
    description: "Shorten your wait time for Despicable Me: Minion Mayhem and other popular attractions like Mario Kart: Koopa's Challenge and Harry Potter and the Forbidden Journey™! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 10,
    description: "Shorten your wait time for Space Fantasy - The Ride and other popular attractions like Mario Kart: Koopa's Challenge and Harry Potter and the Forbidden Journey™! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 11,
    description: "Shorten your wait time for Hollywood Dream - The Ride: Backdrop and other popular attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 12,
    description: "Shorten your wait time for Space Fantasy - The Ride, Despicable Me: Minion Mayhem, and other trending attractions like Mario Kart: Koopa's Challenge! This pass can be used once at four different attractions!"
  },
  {
    productId: 13,
    description: "Shorten your wait time for Space Fantasy - The Ride, The Flying Dinosaur, and other trending attractions like Mario Kart: Koopa's Challenge! This pass can be used once at four different attractions!"
  },
  {
    productId: 14,
    description: "Shorten your wait time for Hollywood Dream - The Ride: Backdrop and other popular attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  // 5.28新增
  {
    productId: 15,
    description: "Shorten your wait time for popular attractions like Yoshi's Adventure! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 16,
    description: "Shorten your wait time for popular attractions like Mario Kart: Koopa's Challenge! This pass can be used once at 3 different attractions!"
  },
  // 8月新增
  {
    productId: 17,
    description: "Shorten your wait time for the Halloween limited attraction Sherlock Holmes - The CURSE of the Rose Sword - !"
  },
  {
    productId: 18,
    description: "Shorten your wait time for Halloween limited attractions GeGeGe no Kitaro - The Real : Haunted Village - and Sherlock Holmes - The CURSE of the Rose Sword - ! This pass can be used once at 2 different attractions!"
  },
  {
    productId: 19,
    description: "Shorten your wait time for the limited-time attraction Demon Slayer: Kimetsu no Yaiba XR Ride and other trending attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 20,
    description: "Shorten your wait time for the limited-time attraction Demon Slayer: Kimetsu no Yaiba XR Ride, SING on Tour, and other trending attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 7 different attractions!"
  },
  {
    productId: 21,
    description: "Shorten your wait time for the limited-time attraction Demon Slayer: Kimetsu no Yaiba XR Ride and other trending attractions like Mario Kart: Koopa's Challenge and Yoshi's Adventure! This pass can be used once at 4 different attractions!"
  },
  {
    productId: 22,
    description: "Shorten your wait time for Hollywood Dream - The Ride: Backdrop and other attractions like Demon Slayer: Kimetsu no Yaiba XR Ride! This pass can be used once at 4 different attractions!"
  },
  // 手环产品详情start
  {
    productId: 23,
    description: "The Power-Up Band brings the experience of playing Super Mario into the real world! Use it to play key challenges, collect coins, and get digital stamps! Both kids and adults alike can unleash their passion to play in an immersive world of interactivity."
  },
  {
    productId: 24,
    description: "The Power-Up Band brings the experience of playing Super Mario into the real world! Use it to play key challenges, collect coins, and get digital stamps! Both kids and adults alike can unleash their passion to play in an immersive world of interactivity."
  },
  {
    productId: 25,
    description: "The Power-Up Band brings the experience of playing Super Mario into the real world! Use it to play key challenges, collect coins, and get digital stamps! Both kids and adults alike can unleash their passion to play in an immersive world of interactivity."
  },
  // 手环产品详情 end
  // 2021.9.9新增快速券
  {
    productId: 26,
    description: "Shorten your wait time for the popular, thrill and family ride like Harry Potter and the Forbidden Journey, Flight of the Hippogriff and Hollywood Dream - The Ride! This pass can be used once at 4 different attractions!"
  }
]