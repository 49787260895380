<template>
  <div class="container">
    <headTab :activetab="'3'" :subtotal="getSubTotal()" :ticketsnum="stockNum" @toShopCartFromHeadTab="toShopCart" />
    <!-- 占位div -->
    <div :style="{ height: $store.state.changeHeight + 'px' }"></div>
    <!-- 内容区start -->
    <div class="main flex-space-between">
      <!-- 内容区左边 -->
      <div style="flex: 1">
        <div class="continue_font" @click="toShopCart()">
          CONTINUE<i class="font_weight_700 el-icon-arrow-right el-icon--right"></i>
        </div>
        <!-- <div style="width:100%" :style="{marginTop:(screenWidth<768?'15px' :'20px')}">
          <image-banner style="width:100%" :boxWidth="screenWidth" />
        </div> -->
        <notice :type="8" />
        <h1 class="big_title" @click="viewDetail">EXTRAS</h1>
        <p class="font16" style="font-weight: normal">
          Have extra options to get more fun at the attractions.
        </p>
        <!-- <p class="tip font16">
          Extra options are available only if you buy certain tickets or express
          passes.
        </p> -->
        <!-- <h2 class="title_2">Power-Up Band Exchange Ticket</h2> -->
        <div class="font14">
          <p class="font_weight_700">
            <!-- Unlock adventures in Super Nintendo World™ with a Power-Up Band! -->
          </p>
          <p>
            <!-- Simply add a Universal Express Pass including a Super Nintendo
            World™ Timed Entry Ticket to your cart and you will be able to
            purchase the Power-Up Band Exchange Ticket. -->
          </p>

          <ul style="padding-left: 15px">
            <!-- <li>Some attractions have set lengths of time.</li>
            <li>For safety reasons, there are established requirements for using the attractions. Please confirm all requirements for each attraction prior to purchasing tickets.</li>
            <li>
              <a href="https://www.usj.co.jp/web/en/us/service-guide/safe" target="_blank">Ride and height requirements for all attractions</a>
            </li>
            <li>Attractions may stop operations and shows may be canceled. If the attractions you select are not in operation, please enjoy the other attractions (not including time-designated attractions). For information regarding canceled shows, please check the detailed page of each Express Pass.</li>
            <li>
              <a href="https://www.usj.co.jp/web/en/us/park-guide/schedule/attraction-closure" target="_blank">Information of temporary closed attractions</a>
            </li>
            <li>There are several requirements regarding the use of Universal Express Passes. Please confirm the requirements of each Universal Express Pass prior to making a purchase.</li> -->
          </ul>
          <!-- <p>Face mask usage is required when experience an attraction, including in the waiting line. Guests who choose not to wear a face mask will not be able to enter or experience the attraction.</p>
          <p class="mt10">For more information about mask requirements, please ask a crew member.</p> -->
        </div>
        <!-- 票列表 -->
        <div v-for="(item, index) in dataList" :key="index">
          <span style="display: none">{{
            descriptionMap(item.productId)
          }}</span>
          <div v-if="dataList.length > 0" class="mt15 tickets">
            <el-card class="box-card" style="margin-bottom: 16px">
              <div slot="header" class="clearfix">
                <p class="ticketType">EXTRAS</p>
                <h3 class="font_weight_700 font20">{{ item.officialName }}</h3>
                <!-- <p class="description">{{ item.officialIntro }}</p> -->
                <p class="description" v-html="item.officialIntro"></p>

                <!-- <div style="margin-bottom:5px;padding-top:20px;color:red;white-space: pre-wrap;" v-if="item.officialIntroColorText">{{ item.officialIntroColorText }}</div> -->
                <!-- 新增文字 -->
                <!-- <span style="color:red;white-space: pre-wrap;text-decoration:underline;background-color: #ffff00;font-weight: 600;">This product will issue 4 QR codes per person after purchase.</span> -->
                <!-- <p>＞Power-up band will be only available for purchase inside the park from the date of entry on January 10th, 2023.</p> -->
                <!-- 新增文字 -->
              </div>
              <div class="card-body flex-space-between">
                <el-image style="width: 1px; height: 1px; visibility: hidden" src="" fit="fill" />
                <!-- 票计数器 -->
                <div class="counter-box">
                  <div class="flex-space-between">
                    <div>
                      <p class="font16" style="text-align: right">
                        per ticket from
                      </p>
                      <p class="font10" style="text-align: right">All Ages</p>
                    </div>
                    <div class="ticketPrice">
                      <p>¥{{ item.productDto.salePrice || 0 | abs }}</p>
                    </div>
                    <div class="input-number">
                      <span  @click="handleVotes(item,'-')" class="blueFont font24 less">-</span>
                      <!-- <span v-else class="blueFont font24 less" style="cursor: not-allowed;background:#eee;color:#dedede">-</span> -->
                      <span class="blueFont boldFont num">{{
                        item.votes
                      }}</span>
                      <span @click="handleVotes(item,'+')" class="blueFont plus">+</span>
                      <!-- <span v-else class="blueFont plus" style="cursor: not-allowed;background:#eee;color:#dedede">+</span> -->
                    </div>
                  </div>
                  <p class="ticket-disclaimer">
                    Prices per ticket vary by day, with tax.
                  </p>
                </div>
              </div>
              <div class="card-foot flex-space-between">
                <el-link class="font16" @click="viewDetail(item)" type="primary">View Details</el-link>
                <el-button v-show="item.votes === 0" :disabled="true" type="info">SELECT A DATE</el-button>
                <el-button v-show="item.votes !== 0" @click="selectDate(item)"  type="primary">SELECT A DATE</el-button>
              </div>
            </el-card>
          </div>
        </div>
      </div>
      <!-- 侧边栏 -->
      <div id="aside" class="aside hidden">
        <div id="asideContent">
          <el-card class="box-card">
            <div @click="toShopCart()" slot="header" class="clearfix flex-space-between card-head" style="position: relative">
              <p class="totalMoney">
                Subtotal:
                <span class="font_weight_700">¥{{ getSubTotal() || 0 | abs }}</span>
              </p>
              <span class="iconfont icon-gouwuche-copy-copy" style="position:absolute;right:8px;font-size:30px;width:46px" :style="{
                  opacity:
                  ticketsCopy.length + expressTicket.length + BraceletListAfterArr.length == 0 ? '.3' : ''
                }">
                <span class="ticketNum">{{
                 stockNum || 0
                }}</span>
              </span>
            </div>
            <div class="card-body-item-base">
              <p @click="toHome()" class="flex-space-between" style="cursor: pointer">
                <span class="ticketType">TICKETS</span>
                <i v-if="haveStudioPass" class="el-icon-circle-check" style="color: rgb(82, 164, 29); font-size: 24px" />
              </p>
              <span v-if="!haveStudioPass" class="font12">Buy Online and Save</span>
            </div>
            <div class="card-body-item-base" @click="toExpressPass()">
              <p class="flex-space-between">
                <span class="ticketType">EXPRESS PASSES</span>
                <i v-if="expressTicket.length > 0" class="el-icon-circle-check" style="color: rgb(82, 164, 29); font-size: 24px" />
              </p>
              <span v-if="expressTicket.length <= 0" class="font12">Select Your Universal Express Pass</span>
            </div>
            <div class="card-body-item-base card-body-item-active">
              <p class="flex-space-between">
                <span class="ticketType">EXTRAS</span>
              </p>
              <div v-if="BraceletListAfterArr.length > 0">
                <transition-group>
                  <div v-for="(item, index) in BraceletListAfterArr" :key="index" class="ticket-item flex-space-between">
                    <div class="item_left">
                      <p>{{ item.officialName }}</p>
                      <p v-if="item.batName.length!==0">{{ item.batName }}</p>
                      <!-- <p class="grayFont">{{ item.productTicketTypeNames }}</p> -->
                      <!-- <p class="grayFont">{{ item.productTicketTypeNames }}</p> -->
                      <p class="grayFont">{{ conversionPeriod(item) }}</p>
                      <p class="grayFont">{{ item.num }} All Ages</p>
                    </div>
                    <a @click="delHandle(index)" class="remove_font">REMOVE</a>
                  </div>
                </transition-group>
              </div>
              <span class="font12" v-else>Select Your Extras</span>
            </div>
          </el-card>
          <div class="aside-btns-box">
            <el-button @click="toExpressPass()" icon="el-icon-arrow-left" class="blueFont font_weight_700 back_btn" style="border: none">BACK</el-button>
            <el-button type="primary" @click="toShopCart()">NEXT STEP</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容区end -->
    <!-- 弹框 -->
    <date-dialog v-if="dateDialogVisible" ref="dateDialog" @openDetail="viewDetail" @handleTicket="handleTicket" />
    <detail-dialog v-if="detailDialogVisible" ref="detailDialog" @selectDate="selectDate" />
  </div>
</template>

<script>
import dateDialog from "./date-dialog.vue";
import headTab from "@/components/headTab";
import detailDialog from "./detail-dialog.vue";
import { getFastTicket } from "@/api/index";
import { formatDate, getScrollTop, judgeMobile } from "@/utils/util";
import { mixin1, mixin2, mixin3 } from "@/mixin/index";
import { descriptions } from "@/assets/detailJSON/product_description.js";
import notice from "@/components/notice";
import ImageBanner from '../../components/imageBanner_top.vue';
let Bracelet = [];
let expressTicketIds = [];
let handleBracelet = [];
export default {
  mixins: [mixin1, mixin2, mixin3],
  components: { dateDialog, headTab, detailDialog, notice, ImageBanner },
  data() {
    return {
      dataList: [],
      dateDialogVisible: false,
      detailDialogVisible: false,
      asideTickets: [],
      orderInfo: {},
      haveStudioPass: false,
      haveFastTickets: false,
      expressTicket: [], // 快速票数组
      tickets: [],
      BraceletArr: [],
      numTotal: 0,
      stockNum: 0,
      correspondingObj: {
        10000379: 1,
        10000369: 2
      }
    };
  },
  async mounted() {
    var asideContent = document.querySelector("#asideContent");
    asideContent.style.top = 40 + "px";
    window.addEventListener("scroll", this.handleScroll);
    await this.handleTicket(); //获取本地侧边栏数据
    this.getData(); //获取手环列表
    this.dataHandle();
    this.getBraceletDataInMixin();
    this.BraceletListAfterArr.forEach(item => {
      console.log(item)
      this.numTotal += item.num
    })

  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取快速票
    getData() {
      expressTicketIds = [];
      //  获取快速票id
      this.expressTicket.forEach(item => {
        if (item.ticketKind == 3) {
          expressTicketIds.push(item.id);
        }
      });

      getFastTicket({ ticketKind: 8 }).then(res => {
        if (res.data.code === 0) {
          Bracelet = res.data.data.list;
          Bracelet.forEach(item => {
            item.votes = 0;
            // 手环的快速票id数组
            // item.productTicketTypeId = item.productTicketTypeId.split(",");
            // item.btnIsDisable = expressTicketIds.some(i =>
            //   item.productTicketTypeId.includes(i.toString())
            // ); // btnIsDisable为true可点击

          });
          this.dataList = Bracelet;
        }
      });
    },
    // 获取本地数据
    handleTicket(val) {
      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        this.orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
        this.asideTickets = this.orderInfo.orderRequestProductList;
        handleBracelet = JSON.parse(localStorage.getItem("orderInfo"))
          .orderRequestProductList;
        this.tickets = this.orderInfo.orderRequestProductList;
        this.expressTicket = [];
        this.asideTickets.map((item, index) => {
          if (item.ticketKind === 3) {
            this.expressTicket.push(...this.asideTickets.splice(index, 1)); //提取出快速票
          }
        });
        const studioTicketType = [1, 2, 5, 7, 9]; // 一日两日票的ticketKind
        for (let i = 0; i < this.asideTickets.length; i++) {
          if (studioTicketType.includes(this.asideTickets[i].ticketKind)) {
            this.haveStudioPass = true;
          } else if (this.asideTickets[i].ticketKind === 3) {
            this.haveFastTickets = true;
          }
        }
        handleBracelet.forEach(item => {
          if (item.ticketKind === 8) {
            this.BraceletArr.push(item); //提取出手环
          }
        });
      }
      setTimeout(() => {
        if (val) {
          this.$router.go(0);
        }
      }, 50);
    },
    // 根据返回的产品id获取对应的产品描述(有设置本地数据就读本地,没有就读服务端传过来的)
    descriptionMap(productId) {
      let id = this.getProductIdMap(productId);
      let description = "";
      descriptions.map(item => {
        if (item.productId == id) {
          description = item.description;
        }
      });
      return description;
    },
    handleVotes(item, type) {
      let max = 10 - this.numTotal
      let num = 0
      this.dataList.forEach(item => {
        num += item.votes
      })
      if (type === '-') {
        item.votes !== 0 ? item.votes-- : ''
        return
      }
      if (num < max) {
        if (type === '+') {
          item.votes++;
        }
      } else {
        return this.$message({
          message: 'Exceeds the maximum quantity',
          type: 'warning',
        })
      }
    },
    // 获取总价
    getSubTotal() {
      let total = 0;
      let expressTicketArr = []
      let onetwoArr = []
      let onetwolength = localStorage.getItem('oneTwoLength') // 更新本地1日，2日，1.5日 数组长度
      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.forEach(item => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            onetwoArr.push(item)
          }
          else if (item.ticketKind === 3) {
            expressTicketArr.push(item); //提取出快速票
          }
        })
      }
      if (onetwoArr.length > 0) {
        onetwoArr.forEach(item => {
          total += item.num * item.price;
        })
      }
      if (expressTicketArr.length > 0) {
        expressTicketArr.forEach(item => {
          total += item.num * item.price;
        })
      }
      if (this.BraceletListAfterArr.length > 0) {
        this.BraceletListAfterArr.forEach(item => {
          total += item.num * item.price;
        })
      }
      this.stockNum = parseInt(onetwolength) + parseInt(expressTicketArr.length) + parseInt(this.BraceletListAfterArr.length)
      return total;
    },
    // 查看详情
    viewDetail(item) {
 
      if (judgeMobile() === undefined) {
        this.detailDialogVisible = true;
        this.$nextTick(() => {
        this.$refs.detailDialog.init(item);
      })
      } else {
        this.$router.push({ path: "/optionsdetaildialog", query: { item: encodeURI(JSON.stringify(item)) } });
      }

    },
    // 打开时间弹框
    selectDate(item) {
      this.$store.commit("SET_SCROLLTOP", getScrollTop());
      if (judgeMobile() === undefined) {
        this.dateDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.dateDialog.init(item);
        });
      } else {
        this.$router.push({ path: "/optionsDateDialog", query: { item: encodeURI(JSON.stringify(item)) } });
      }
    },
    toHome() {
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        let sessionArr = JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList //缓存原数组
        let AftersessionArr = []  //用来放除了手环之外的数组
        sessionArr.forEach((item) => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 3 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            AftersessionArr.push(item)
          }
        })
        AftersessionArr = AftersessionArr.concat(this.BraceletListAfterArr)
        this.orderInfo.orderRequestProductList = AftersessionArr;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      }
      this.$router.push({ path: "/" });
    },
    // 删除票
    delHandle(index) {
      this.asideTickets = this.asideTickets.filter(item => {
        return item.ticketKind !== 8;
      });
      this.BraceletArr.splice(index, 1);
      this.asideTickets.push(...this.BraceletArr);
      this.asideTickets.push(...this.expressTicket);
      this.orderInfo.orderRequestProductList = [];
      this.orderInfo.orderRequestProductList = this.asideTickets;
      localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      this.handleTicket();
      this.$router.go(0);
    },
    delTicket() { },
    // 设置有限期范围
    conversionPeriod(item) {
      var startTime = item.travelDate;
      startTime = formatDate(startTime);
      return `${startTime}`;
    },
    toShopCart() {

      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        let sessionArr = JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList //缓存原数组
        let AftersessionArr = []  //用来放除了手环之外的数组
        sessionArr.forEach((item) => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 3 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            AftersessionArr.push(item)
          }
        })
        AftersessionArr = AftersessionArr.concat(this.BraceletListAfterArr)
        this.orderInfo.orderRequestProductList = AftersessionArr;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      }

      this.$router.push({ path: "/shopCart" });
    },
    toExpressPass() {
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        let sessionArr = JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList //缓存原数组
        let AftersessionArr = []  //用来放除了手环之外的数组
        sessionArr.forEach((item) => {
          if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 3 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
            AftersessionArr.push(item)
          }
        })
        AftersessionArr = AftersessionArr.concat(this.BraceletListAfterArr)
        this.orderInfo.orderRequestProductList = AftersessionArr;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      }
      this.$router.push({ path: "/expressPass" });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  .main {
    width: 1200px;
    margin: 0 auto;
    .aside {
      margin-left: 30px;
      #asideContent {
        position: relative;
        top: 0;
        width: 240px;
      }
      .card-body-item-base {
        padding: 16px;
        padding-bottom: 24px;
        max-height: 300px;
        overflow: auto;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
      }
      .card-body-item-active {
        border-left: 2px solid #0959c2;
        background-color: #f9fcff;
      }
    }
  }
}
.big_title {
  margin: 19.92px 0;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 900;
  color: #333333;
}
.continue_font {
  display: none;
}
::v-deep.aside-btns-box {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .back_btn {
    padding: 0;
    margin-right: 30px;
    span {
      vertical-align: middle;
    }
  }
  .el-button {
    font-size: 12px;
  }
  .el-button--primary {
    border-radius: 12px;
    font-weight: 700;
    height: 31px;
    line-height: 31px;
    padding: 0 15px;
    background-color: #036ce2;
    margin-left: 0;
  }
}
.title_2 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 900;
  margin: 0;
  color: #333;
  margin-bottom: 5px;
}
.tip {
  padding: 15px 0.9375rem;
  background: -webkit-linear-gradient(to bottom, #1d3586, #3555a3);
  background: -o-linear-gradient(to bottom, #1d3586, #3555a3);
  background: -moz-linear-gradient(to bottom, #1d3586, #3555a3);
  background: linear-gradient(to bottom, #1d3586, #3555a3);
  color: white;
  margin: 15px 0 20px 0;
  font-weight: 500;
}
.card-foot {
  border-top: 1px solid gainsboro;
  padding: 24px 24px;
  .el-button {
    border-radius: 12px;
    font-weight: 700;
  }
  .el-link {
    font-weight: 500;
    color: rgb(3, 108, 226);
  }
  .el-button--primary {
    background-color: #036ce2;
  }
}
.ticket-img {
  width: 113px;
  height: 75px;
}
.counter-box {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  margin-top: 15px;
  .ticketPrice {
    margin-left: 16px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 700;
  }
}
.totalMoney {
  // margin-right: 20px;
  vertical-align: super;
  font-weight: 400;
  font-size: 16px;
}
.aside {
  .ticketType {
    cursor: pointer;
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
}
.card-head {
  align-items: center;
  .ticketNum {
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    background-color: rgb(22, 43, 117);
    color: white;
    text-align: center;
    font-size: 12px;
    top: 6px;
    right: 15px;
  }
}
.ticket-disclaimer {
  text-align: right;
  margin-top: 8px;
  margin-bottom: 35px;
  font-size: 10px;
}
/**票计数器start**/
.input-number {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 24px;
  margin-left: 25px;
  margin-top: -10px;
  display: flex;
  align-items: center;
  .num {
    width: 40px;
    display: inline-block;
    text-align: center;
  }
  .plus {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid gainsboro;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
  }
  .less {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid gainsboro;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
  }
}
/**票计数器end**/
.el-card {
  border-radius: 15px;
}
/* 删除产品动画过度start*/
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}
.ticket-item {
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
  color: #333333;
  .item_left {
    width: 132.47px;
  }
  .remove_font {
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    color: #036ce2;
    min-width: 55px;
  }
}
.main-head {
  padding: 15px;
  align-items: center;
}
::v-deep .aside-btns-box [class^="el-icon-"] {
  font-size: 24px;
  vertical-align: middle;
  font-weight: 700;
}
/* 删除产品动画过度end*/
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 0.9375rem;
      box-sizing: border-box;
    }
  }
  .counter-box {
    .ticketPrice {
      margin-left: 8px;
      margin-right: 10px;
    }
  }
  .input-number {
    margin-left: 0;
  }
}
@media (max-width: 780px) {
  .hidden {
    display: none;
  }
  .container .main {
    padding-top: 120px;
  }
  .continue_font {
    font-size: 12px;
    display: block;
    text-align: right;
    color: #036ce2;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }
  .card-foot {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px 16px;
    .el-link {
      padding-top: 16px;
    }
  }
  ::v-deep .el-icon-arrow-right:before {
    font-size: 24px;
    vertical-align: middle;
  }
  .ticket-disclaimer {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .input-number {
    min-width: 88px;
  }
}
::v-deep .tickets .el-card__body {
  padding: 0;
}
::v-deep .el-card__header {
  border-bottom: none;
}
::v-deep .el-icon-shopping-cart-2:before {
  font-size: 30px;
}
::v-deep .tickets .el-card__header {
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
}
.tickets {
  .ticketType {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .description {
    font-size: 16px;
    line-height: 1.5;
    font-weight: normal;
    color: #333;
    padding: 0;
    text-align: left;
  }
}
::v-deep .aside .el-card__header {
  background-color: #036ce2;
  color: white;
  padding: 10px 8px 10px 16px;
}
::v-deep .aside .el-card {
  max-width: 300px;
}
::v-deep .aside .el-card__body {
  padding: 0;
  // padding-bottom: 15px;
}
::v-deep .el-link.is-underline:hover:after {
  border-bottom: none;
}
ul a {
  color: #003399;
  text-decoration: underline;
}
</style>
