<template>
  <div>
    <el-dialog v-if="dialogVisible" @close="closeDialog()" :visible.sync="dialogVisible" class="dialog-900" append-to-body width="100%">
    <div class="dialog_body_md">
      <h2 class="big_title">{{ productObj.officialName }}</h2>
      <el-row class="dialog-body" style="margin-left:0;margin-right:0;">
        <!-- 左边文本 start -->
        <el-col :span="24" :md="8" class="didalog-body-left" style="padding:0 23px 0 0;">
          <p style="color:#0A0A0A;text-align:left;word-break: normal;">
           {{ productObj.productDescription }}
          </p>

          <el-link type="primary" class="mt15 font12" style="color:#036CE2;" @click="handleDetail">View Details</el-link>
        </el-col>
        <!-- 左边文本 end -->
        <!-- 日历价格部分start 1 -->
        <el-col :span="24" :md="16" class="dialog-body-right" v-loading="loading" style="min-height:380px;">
          <div v-if="newifTodayLater" class="gwcts">Please clear your cart if you wish to purchase tickets for other dates.</div>
          <div v-if="step===1" style="margin-top:5px;">
            <p class="currentDate">
              <!-- <i @click="prevMonth()" class="el-icon-arrow-left mr15 calendar_arrow" :style="{visibility: date.getMonth() <= new Date().getMonth() && date.getFullYear() <= new Date().getFullYear() ? 'hidden' : ''}" /> -->
              <img :src="leftIconPath" @click="prevMonth()" style="vertical-align: initial;cursor: pointer;" class="mr15 calendar_arrow" :style="{
                  visibility:
                    date.getMonth() <= new Date().getMonth() &&
                    date.getFullYear() <= new Date().getFullYear()
                      ? 'hidden'
                      : ''
                }" />
              <span class="mr15 darkBlueFont">{{ dateFilter(date) }}</span>
              <img :src="rightIconPath" @click="nextMonth()" class="calendar_arrow" :style="{ visibility: beforeEndMonth ? '' : 'hidden' }" style="vertical-align: inherit;cursor: pointer;" />
              <!-- <i @click="nextMonth()" class="el-icon-arrow-right calendar_arrow" :style="{visibility: beforeEndMonth()? '' : 'hidden' }" /> -->
            </p>
            <!-- 日历票列表 -->
            <el-calendar v-model="date" :first-day-of-week="7" :class="{
                'preMonth-disclick': date.getMonth() <= new Date().getMonth(),
             
              }"><template slot="dateCell" slot-scope="{ data }">
                <div v-if="isShowBefore" @click="selectDate(data)" class="dateCell font12" :class="{'datecell_disclick' : beforeToday(data.day) || InventoryShortage(data.day) || notOnSelectableDate(data.day), 'datecell_click' : !beforeToday(data.day) && !InventoryShortage(data.day) && !notOnSelectableDate(data.day), 'click_active' : travelDate === data.day}">
                  <p style="text-align:right;">{{ data.day.split('-')['2'] }}</p>
                  <p v-if="betweenDay(data.day) && notOnSelectableDate(data.day)">Disabled</p>
                  <p v-else-if="!notOnSelectableDate(data.day) && InventoryShortage(data.day) && betweenDay(data.day)"> <span style="color:#ec1018;font-weight:bold;font-size:10px">No Stock</span></p>
                  <div v-else v-for="(item, index) in priceCalendar" :key="index" class="font12">
                    <p v-if="data.day === item.travelTime" style="display:block;padding-top:10px">¥{{ item.salePrice || 0 | formataPrice }}</p>
                  </div>
                </div>
                <!-- <div class="dateCell font12"  v-else >
                  <p style="text-align:right;">{{ data.day.split('-')['2'] }}</p>
                  <p v-if="betweenDay(data.day) && notOnSelectableDate(data.day)">Disabled</p>
                  <p v-else-if="!notOnSelectableDate(data.day) && InventoryShortage(data.day) && betweenDay(data.day)">Available at park</p>
                  <div v-else v-for="(item, index) in priceCalendar" :key="index" class="font12">
                    <p v-if="data.day === item.travelTime" style="display:block;padding-top:10px">¥{{ item.salePrice || 0 | formataPrice }}</p>
                  </div>
                </div> -->
              </template>
            </el-calendar>
          </div>
          <div v-if="step === 2" class="sessions_box">
            <h3 class="font_weight_700 mb10">Choose session</h3>
            <!-- 场次 -->
            <!-- <el-checkbox-group v-model="session" @change="periodChange()">
              <el-checkbox  v-for="(item,index) in sessions" :label="item.periodValue" :key="index" style="display:block">{{item.periodName}}</el-checkbox>
            </el-checkbox-group> -->
            <div class="session_list" v-loading="loading">
              <template v-if="sessions.length > 0">
                <el-radio v-model="session" v-for="(item, index) in sessions" :key="index" :disabled="item.num < productObj.votes" :label="item.periodValue" @change="periodChange">
                  <p v-for="(item, index) in splitPeriodName(item.periodName)" :key="index">{{ item }}</p>
                  <p>(The amount of stocks: {{ item.num }})</p>
                </el-radio>
                <!-- <el-radio v-model="session" v-for="(item, index) in sessions" :key="index" :label="item.periodValue" @change="periodChange">
                  <p v-for="(item, index) in splitPeriodName(item.periodName)" :key="index">{{ item }}</p>
                </el-radio> -->
              </template>
              <div v-else>No Session</div>
            </div>
          </div>
        </el-col>
        <!-- 日历价格部分end -->

      </el-row>
    </div>
    <el-row>
      <el-progress v-if="timeSession" :percentage="step == 1 ? 50:100"></el-progress>
      <el-progress v-else :percentage="100"></el-progress>
    </el-row>
    <el-row class="dialog-foot">
      <div class="price_box" style="margin-bottom: 8px;">
        <span class="subtotal">Subtotal:</span>
        <span class="price">¥{{ productObj.votes * product.salePrice || 0 | abs }}</span>
      </div>
      <div class="foot-btns">
        <div class="d-flex">
          <el-button class="blueFont cancel_btn" @click="cancel()" style="border-color:#036ce2;">CANCEL</el-button>
          <el-button :disabled="addCartBtnDisable" v-if="!timeSession" @click="addToCart()" type="success">ADD TO CART</el-button>
          <div v-if="timeSession" style="margin-left:1rem">
            <el-button  v-if="step===1" @click="nextStep()" type="primary">NEXT</el-button>
            <el-button :disabled="session===''" v-if="step===2" @click="addToCart()" type="success">ADD TO CART</el-button>
          </div>
        </div>
      </div>
      <div class="m-t-60" />
    </el-row>
    <close-icon v-if="dialogVisible" @closeDialog="closeDialog()" />
    </el-dialog>
    <notice-dialog @handleTicketFn="handleTicketFn" ref="noticeDialog"></notice-dialog>
  </div>
  
</template>

<script>
import noticeDialog from './notice-dialog'
import { getCalendarProductList,getPeriodList } from "@/api/index";
import closeIcon from "@/components/close_icon.vue";
import { mixin2, ifTodayLater } from "@/mixin/index.js";
import { getDateStr, judgeMonthFrist, judgeIphone, returnDateResults } from "@/utils/util";
import axios from "axios";
import { dateTimeFormatByZone } from "@/utils/date.js";
import { mapState } from 'vuex'
let localStorageOrderArr = JSON.parse(localStorage.getItem("orderInfo")) ? JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList : []
export default {
  mixins: [mixin2],
  components: { closeIcon, noticeDialog },
  computed: {
    ...mapState(['oneDayTicketAvailableDate', 'maxSaleObj']),
    // 判断当前月份是否在当天开始的60天之内
    beforeEndMonth() {
      let res;
      if (this.endTime) {
        let endYear = this.endTime.split("-")[0];
        // let endMonth = parseInt(this.endTime.split('-')[1]) - 1 < 10 ? `0${parseInt(this.endTime.split('-')[1]) - 1}` : parseInt(this.endTime.split('-')[1]) - 1
        // endMonth === '00' ? endMonth = '01' : ''
        let endMonth = Number(this.endTime.split('-')[1])
        let endDay = this.endTime.split("-")[2];
        let endDate = new Date(`${endYear}-${endMonth}-${endDay}`);
        let curYear = this.date.getFullYear();
        let curMonth = this.date.getMonth() + 1 < 10 ? `0${this.date.getMonth() + 1}` : this.date.getMonth() + 1;
        let beginDate = new Date(`${curYear}-${curMonth}-${endDay}`);
        if (endDay !== '31') {
          if (beginDate < new Date(this.endTime)) {
            res = true;
          } else {
            res = false;
          }
        } else {
          endMonth = endMonth < 10 ? `0${endMonth}` : endMonth;
          res = false
          // parseInt(curMonth) < parseInt(endMonth) ? res = true : res = false
          parseInt(curYear.toString()+curMonth.toString()) < parseInt(endYear.toString()+endMonth.toString()) ? res = true : res = false
        }
      }
      return res;
    }
  },
  data() {
    return {
      date: new Date(),
      step:1,
      timeSession:false,
      productObj: {},
      session: "", //所选场次值
      sessions: [], // 所有场次
      sessionName: [], //所选场次名
      travelDate: "", // 游玩日期
      subtotal: 0,
      orderInfo: {},
      priceCalendar: [], // 日历价格
      product: {}, // 所选日期的产品信息
      dialogVisible: false,
      loading: false,
      btnDisabled: true,
      addCartBtnDisable: true,
      timeZoneList: [], // 时区列表
      onTimeZone: 1, // 时区值
      zone: "",
      endTime: "",
      isShowBefore: true,
      leftIconPath: require("../../assets/left.png"),
      rightIconPath: require("../../assets/right_arrow.png"),
      differDay: 0,
      isIphone: false,
      isMac: null,
      newifTodayLater: false,
    };
  },
  watch: {
    date() { }
  },
  methods: {
    async init(item) {
      this.newifTodayLater = ifTodayLater();
      console.log(item)
      item.productTimeSession===1?this.timeSession=true:this.timeSession=false //此手环是否有时间场次
      this.isIphone = judgeIphone() //判断是否iPhone 是 true 否false
      this.isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      this.differDay = this.maxSaleObj.bracelet === undefined ? returnDateResults(this.date) : returnDateResults(this.date, new Date(this.maxSaleObj.bracelet))
      // this.differDay = 61
      this.dialogVisible = true;
      this.product = {};
      this.date = new Date();
      await this.getTimezones(); // 获取时区
      this.productObj = item;
      this.getCalendarProductList();
      // judgeMonthFrist(this.date.getMonth(), 0)
    },
    // 获取日历票列表
    async getCalendarProductList() {
      this.loading = true;
      const date = this.date;
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const startTime = this.getNowFormatDate();
      this.endTime = getDateStr(startTime, this.differDay);
      const params = {
        startTime,
        endTime: this.endTime,
        ticketKind: this.productObj.ticketKind || 99,
        productId: this.productObj.productId || 1
      };
      getCalendarProductList(params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          res.data.data.map(item => {
            if (item.travelTime !== undefined) {
              this.onTimeZone = item.productDto.onTimeZone;
              item.travelTime = dateTimeFormatByZone(
                item.travelTime,
                this.zone,
                "YYYY-MM-DD"
              );
              const maxNum = item.productDto.maxNum; // 最大预订数
              const num = item.num; // 库存
              if (maxNum === 0) {
                item.num = num;
              } else if (maxNum > 0) {
                if (maxNum >= num) {
                  item.num = num;
                } else if (num >= maxNum) {
                  item.num = maxNum;
                }
              }
            }
          });
          this.priceCalendar = res.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.priceCalendar = [];
        }
      });
    },
    // 加入购物车
    addToCart() {
      // this.$gtag.event('add-to-cart')
      // google统计-分销商访问商品事件埋点

      this.$gtag.event("add-to-cart-Express-Pass", {
        event_category: "expressPassAddToCart",
        event_label: "addToCart",
        value: 1000
      });
      var orderInfo = this.setProData();
      orderInfo.currency = 5
      localStorage.setItem("orderInfo", JSON.stringify(orderInfo)); // 保存到本——
      this.$message({
        message: "Add to cart successfully",
        type: "success"
      });
      // 记录当前的时间-三小时后清除购物车-10800000 结束
      // localStorage.setItem('startAddCartDate',+new Date()) 
      localStorage.setItem('endAddCartDate',+new Date()+10800000) 

      this.closeDialog();
      console.log('init');
      this.$refs.noticeDialog.init()
      // return
      // this.$emit("handleTicket", 1); // 侧边栏更新数据

    },
    handleTicketFn(){
      this.$emit("handleTicket", 1); // 侧边栏更新数据
    },
    // 数据处理
    setProData() {
      // return
      const expressTicket = {
        id: this.product.id,
        travelDate: this.travelDate,
        price: this.product.salePrice,
        num: this.productObj.votes,
        productId: this.product.productDto.id,
        subtotal: this.productObj.votes * this.product.salePrice,
        title: this.product.productDto.title,
        ticketKind: this.productObj.ticketKind,
        // expressTicketIds: this.productObj.productTicketTypeId, // 所包含的快速券的id组成的数组
        officialName: this.productObj.officialName, //官网名字
        officialIntro: this.productObj.officialIntro, //官网简介
        batNo: this.session, // 场次值
        batName: this.sessionName, //场次名
        Currentinventory: this.Currentinventory, //场次库存
      };
      var orderInfo = JSON.parse(localStorage.getItem("orderInfo")); // 本地购物车:存储了所有已选产品信息
      // 如果本地已经存储过产品就添加
      if (orderInfo) {
        orderInfo.orderRequestProductList.push(expressTicket);
      } else {
        // 否则新建并存储到本地
        var orderInfo = {};
        orderInfo.orderRequestProductList = [];
        orderInfo.orderRequestProductList.push(expressTicket);
        orderInfo.currency = 5;
      }
      return orderInfo;
    },
    // 判断日期是否在可选日期内,不在则返回true
    notOnSelectableDate(date) {
      let notOn = false
      // 如果有日期限制
      if (this.oneDayTicketAvailableDate.length > 0) {
        this.oneDayTicketAvailableDate.includes(date) ? (notOn = false) : (notOn = true)
      }
      return notOn
    },
    nextStep(){
       // 如果没选产品
       if (JSON.stringify(this.product) == '{}') {
        return this.$message({
          message: 'Please select product first',
          type: 'warning',
        })
      }
      if (!this.travelDate) {
        return this.$message({
          message: 'Please select a play date',
          type: 'warning',
        })
      }
      this.getPeriodList() // 获取场次
      this.step = 2
    },
    // 点击日期
    selectDate(date) {
      this.travelDate = date.day;
      this.getProduct();
    },
       // 分割场次的每条数据
       splitPeriodName(name) {

const splitArr = name.split(')')
for (let i = 0; i < splitArr.length; i++) {
  splitArr[i] += `)`
}
return splitArr.splice(0, splitArr.length - 1)
},
// 选中场次
periodChange() {
this.addCartBtnDisable = false
this.sessions.map((item) => {
  if (item.periodValue == this.session) {
    this.Currentinventory = item.num
    this.sessionName = item.periodName
  }
})
},
       // 获取场次
    getPeriodList() {
      const params = {
        travelDate: this.travelDate,
        interfaceId: this.product.productDto.interfaceId,
        thirdProductId: this.product.productDto.interfaceProdId,
      }
      this.loading = true
      getPeriodList(params).then((res) => {
        if (res.data.code === 0) {
          this.sessions = res.data.data
          this.loading = false
        }else{
          console.log('错误')
        }
      })
    },
    // 获取所选日期的产品信息
    getProduct() {
      let haveProduct = false;
      if (this.priceCalendar.length <= 0) {
        return this.$warning("Inventory shortage");
      } else {
        for (let i = 0; i < this.priceCalendar.length; i++) {
          if (this.priceCalendar[i].travelTime === this.travelDate) {
            haveProduct = true;
            break;
          }
        }
      }
      // 如果当天没产品就提示
      if (!haveProduct) {
        return this.$message({
          message: "No related products on the day",
          type: "warning"
        });
      } else {
        for (let i = 0; i < this.priceCalendar.length; i++) {
          if (
            this.travelDate === this.priceCalendar[i].travelTime &&
            this.productObj.votes > this.priceCalendar[i].num
          ) {
            this.$warning("Inventory shortage");
            this.btnDisabled = true;
            break;
          } else if (this.travelDate === this.priceCalendar[i].travelTime) {
            this.product = this.priceCalendar[i];
            this.btnDisabled = false;
            this.addCartBtnDisable = false;
            break;
          }
        }
      }
    },
    // 获取时区列表
    async getTimezones() {
      await axios
        .get("https://nta.tripodeck.com/api/ops/app/timezones")
        .then(res => {
          if (res.data.code === 0) {
            this.timeZoneList = res.data.data;
          } else {
            this.timeZoneList = [];
          }
        });
      this.getTimeZone();
    },
    // 获取单个时区的zone
    getTimeZone() {
      if (this.timeZoneList.length > 0) {
        this.timeZoneList.map(item => {
          if (item.value === this.onTimeZone) {
            this.zone = item.zone;
          }
        });
      }
    },
    // cancel按钮
    cancel() {
      this.closeDialog(); // 关闭弹框重置数据
    },
    InventoryShortage(day) {
      let noticket = false;
      // 有库存并且日历数据不是0
      if (this.productObj.votes > 0 && this.priceCalendar.length > 0) {
        // 查看今天是否有库存
        const index = this.priceCalendar.findIndex(item => {
          return item.travelTime === day;
        });
        if (index === -1) {
          // 没有返回-1 并返回true
          noticket = true;
        } else {
          // 有库存
          this.priceCalendar.map(item => {
            // 如果客户所选的数量大于库存也返回没有库存
            if (item.travelTime === day && this.productObj.votes > item.num) {
              noticket = true;
            }
          });
        }
      } else if (this.priceCalendar.length === 0) {
        // 日历没有数据返回没有库存
        noticket = true;
      }
      return noticket;
    },
    // 判断日期是否在六十天内
    betweenDay(day) {
      const startTime = new Date(this.getNowFormatDate());
      const endTime = new Date(getDateStr(startTime, this.differDay));
      const curTime = new Date(day);
      if (startTime <= curTime && curTime <= endTime) {
        return true;
      } else {
        return false;
      }
    },
    // 关闭弹框
    closeDialog() {
      this.date = new Date()
      document.documentElement.scrollTop = this.$store.state.scrollTop;
      // 重置数据
      this.dialogVisible = false;
      this.session = "";
      this.btnDisabled = true;
      this.addCartBtnDisable = true;
      this.step=1
      this.travelDate=''
      this.priceCalendar = [];
    },
    // 点击上个月
    prevMonth() {
      let prevMon = this.date.toString().indexOf('GMT-') === -1 ? this.date.getMonth() : this.date.getMonth() + 1
      // judgeMonthFrist(prevMon - 1, 0)
      prevMon < 10 ? prevMon = '0' + prevMon : ''
      if (prevMon.toString().substr(0, 1) !== "0") {
        prevMon < 10 && this.isMac ? prevMon = '0' + prevMon : ''
      }
      let prevYear = this.date.getFullYear()
      if (prevMon - 1 < 0) {
        prevMon = 12
        prevYear = prevYear - 1
      }
      this.date = new Date(`${prevYear}-${prevMon}-01`)
      this.loading = true
      this.isShowBefore = false
      setTimeout(() => {
        this.loading = false
        this.isShowBefore = true
      }, 10);
      this.getCalendarProductList(0);

    },
    // 点击下个月
    nextMonth() {
      let nextMon = this.date.toString().indexOf('GMT-') === -1 ? this.date.getMonth() + 2 : this.date.getMonth() + 2
      // judgeMonthFrist(nextMon - 1, 12)
      nextMon < 10 ? nextMon = '0' + nextMon : ''
      if (nextMon.toString().substr(0, 1) !== "0") {
        nextMon < 10 && this.isMac ? nextMon = '0' + nextMon : ''
      }
      let year = this.date.getFullYear()
      if (parseInt(nextMon) > 12) {
        nextMon = '01'
        year = year + 1
      }
      this.date = new Date(`${year}/${nextMon}/01`)
      // this.getCalendarProductList()

    },
    // 格式化日期显示
    dateFilter(date) {
      var month = date.getMonth();
      var year = date.getFullYear();
      var options = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
      };
      if (month < 12) {
        return options[month + 1] + " " + year;
      } else {
        return options[1] + " " + (year + 1);
      }
    },
    // 获取当天的年月日
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    // 打开详情
    handleDetail() {
      this.dialogVisible = false;
      this.$emit("openDetail", this.productObj);
    },
    disableDate(day) {
      let noticket = false; //标识
      localStorageOrderArr.forEach(item => {
        if (item.ticketKind === 3) {
          item.travelDate == day ? (noticket = false) : (noticket = true);
        }
      });
      return noticket;
    }
  }
};
</script>
<style lang="scss" scoped>
.big_title {
  margin: 30px 32px 24px;
  font-size: 24px;
  font-weight: 900;
  color: #333333;
}
.dialog-body {
  padding: 0 1px 0 32px;
}
.back-btn {
  background-color: inherit;
  border: none;
  &:hover {
    background: inherit;
  }
}
.back-btn-hidden {
  visibility: hidden;
}
.foot-btns {
  .el-button {
    border-radius: 13px;
  }
  .el-button--primary {
    background-color: #036ce2;
  }
  .el-button--success {
    background-color: #52a41d;
  }
}
.dateCell {
  min-height: 55.99px;
  padding: 2px 4px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
::v-deep .el-calendar-table td {
  border: 1px solid black;
  background-color: #eeeeee;
}
::v-deep .el-calendar-table td .el-calendar-day {
  /*日历格不可点击样式 */
  .datecell_disclick {
    background-color: rgb(238, 238, 238);
    color: #333333;
    & {
      pointer-events: none;
    }
  }
  // 点击后的样式
  .click_active {
    background-color: rgb(22, 43, 116) !important;
    color: white !important;
  }
  /*日历格可点击样式 */
  .datecell_click {
    background-color: white;
    color: #333;
    &:hover {
      background-color: rgb(22, 43, 116);
      color: white;
    }
  }
}
.session_list {
  // max-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .el-radio {
    margin-bottom: 10px;
    white-space: normal;
    display: flex;
  }
}
::v-deep .el-dialog {
  max-width: 900px;
  // max-height: 95vh;
  margin-top: 11vh !important;
  border-radius: 12px;
}
/deep/ .el-calendar__header {
  border-bottom: 0;
  padding: 0;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .dialog-body-right {
  border-left: 1px solid #a1a1a1;
  .gwcts{
    padding: 8px;
    border: 2px solid red;
    color: red;
    margin-left: 15px;
    margin-right: 20px;
    margin-bottom: 12px;
    background-color:#fbf1f0;
    border-radius: 6px;
  }
  .currentDate {
    font-size: 18px;
    line-height: 20px;
    color: rgb(22, 43, 117);
    font-weight: 700;
    text-align: center;
    i {
      vertical-align: bottom;
      font-weight: 600;
    }
  }
}
/deep/ .el-calendar__body {
  padding: 0 32px 0px 15px;
}
/deep/ .dialog-foot {
  text-align: right;
  background-color: #eeeeee;
  padding: 32px 32px 16px;
  border-radius: 0 0 12px 12px;
  // border-top: 6px solid rgb(3, 108, 226);
  .price_box {
    text-align: right;
    .subtotal {
      padding-right: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
    .price {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
      color: #0a0a0a;
    }
  }
}
/deep/ .el-progress-bar {
  padding-right: 0;
}
/deep/ .el-progress-bar__inner {
  background-color: #036ce2;
}
/deep/ .el-progress__text {
  display: none;
}
// 重置进度条样式
/deep/ .el-progress {
  top: 4px;
  .el-progress-bar__outer {
    border-radius: 0;
    .el-progress-bar__inner {
      border-radius: 0;
    }
  }
}
/deep/ .el-checkbox-group {
  margin-bottom: 80px;
  max-height: 500px;
  overflow: auto;
}
/deep/ .el-calendar-table thead th {
  color: #333333;
  font-weight: bold;
}
/deep/ .el-calendar__title {
  display: none;
}
/deep/ .el-calendar__button-group {
  display: none;
}
::v-deep .el-calendar-table .el-calendar-day {
  // min-width: 80px;
  height: auto;
  padding: 0;
}
::v-deep .el-calendar-table td.is-selected {
  background-color: rgb(22, 43, 117) !important;
  color: white !important;
}
::v-deep .el-calendar-table td.is-today {
  color: inherit;
  background-color: inherit;
}
::v-deep .el-calendar-table .el-calendar-day:hover {
  background-color: rgb(22, 43, 117);
  color: white;
}
::v-deep .el-calendar-table tr td:first-child {
  // border-left: 1px solid rgb(151, 151, 151);
}
::v-deep .el-calendar-table tr:first-child td {
  border-top: 1px solid rgb(151, 151, 151);
}

.dialog_body_md {
  height: 54vh;
  overflow: auto;
}
.back_btn_top_box {
  display: none;
  position: fixed;
  top: 0;
  // width: 100%;
  background-color: white;
  z-index: 1;
  .back_btn_top {
    padding-bottom: 9px;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #036ce2;
    background-color: inherit;
    border: none;
    padding-left: 16px;
    ::v-deep .el-icon-arrow-left::before {
      font-weight: 700;
    }
  }
}
.sessions_box {
  margin-left: 16px;
}

@media (max-width: 1370px) {
  /deep/ .dialog-foot {
    padding-top: 8px;
    // border-top: 6px solid rgb(3, 108, 226);
  }
}
@media (max-width: 992px) {
  /deep/ .dialog-body-right {
    border-left: 0;
  }
  .dialog_body_md {
  height:58vh;
  overflow: auto;
}
  /deep/ .el-calendar__body {
    padding-right: 15px;
    padding-left: 0;
  }
  .big_title {
    margin: 30px 12px 24px;
  }
  .dialog-body {
    padding: 0 1px 0 12px;
  }
}

@media (max-width: 768px) {
  /deep/ .el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0;
    height: 100vh;
    border-radius: 0;
    .el-dialog__body {
      height: 100%;
      .dialog-body {
        height: inherit;
      }
    }
  }
  .dialog_body_md {
    height: calc(100vh - 192px);
  }
  ::v-deep .dialog-foot {
    border-radius: 0;
    padding: 16px;
    // border-top: 6px solid rgb(3, 108, 226);
  
    .back-btn {
      display: none;
    }
    .price_box {
      display: flex;
      justify-content: space-between;
    }
    .foot-btns {
      div {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        .cancel_btn {
          background-color: inherit;
          border: none;
        }
      }
      .el-button + .el-button {
        margin-left: 0;
      }
    }
    .m-t-60 {
      margin-top: 60px;
    }
  }
  .back_btn_top_box {
    display: inline-block;
  }
  /deep/ .sessions_box {
    margin-left: 0;
  }
  .big_title {
    margin: 30px 12px 24px;
  }
  .dialog-body {
    padding: 0 1px 0 12px;
  }
}
.padding_b_16 {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  ::v-deep .el-calendar-table .el-calendar-day {
    padding-top: 0;
  }
}

// 设置日历箭头hover样式为手指
.el-icon-arrow-left,
.el-icon-arrow-right {
  cursor: pointer;
}
::v-deep .el-calendar-table {
  &:not(.is-range) {
    td.current,
    td.is-selected {
      border: 1px solid black;
    }
    td.next {
      border: none;
      display: none;
    }
    td.prev {
      visibility: hidden;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #333333;
    }
  }
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #036ce2;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #036ce2;
  background: #036ce2;
}
::v-deep .el-loading-mask {
  right: -1px;
}
// 时间为八月份时隐藏日历第一行，临时解决八月份时第一行没有顶上的问题
/deep/.hiddenPre {
  .el-calendar-table:not(.is-range) td.prev {
    display: none;
  }
}
.d-flex{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
// 重置进度条样式
/deep/ .el-progress {
  top: 4px;
  .el-progress-bar__outer {
    border-radius: 0;
    .el-progress-bar__inner {
      border-radius: 0;
    }
  }
}
</style>
