<template>
  <div v-if="this.noticeObj" v-html="this.noticeObj.content">

  </div>
</template>
<script>
import { getNotice } from '../api/index'
export default {
  props: {
    type: Number
  },
  mounted() {
    getNotice({ noticeType: this.type }).then(res => {
      if (res.data.data.list[0].dataStatus === 2) {
        this.noticeObj = res.data.data.list[0]

      }
    })
  },
  data() {
    return {
      noticeObj: []
    }

  }
}
</script>
<style lang="scss" >
.notice {
  border: 2px solid;
  background-color: rgb(251, 241, 240);
  border-color: rgb(236, 16, 24);
  border-radius: 7px;
  padding: 24px;
  margin: 19.92px 0;
  .head {
    display: flex;
    align-items: center;
    line-height: 33px;
    padding-bottom: 8px;
    margin-bottom: 24px;
    border-bottom: 1px solid #333;
    .tip-icon {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }
    h4 {
      color: #333;
      font-weight: 900;
      font-size: 18px;
    }
  }
  .text {
    color: #036ce2;
    font-weight: 700;
    font-size: 16px;
    a:visited {
      color: #036ce2;
    }
  }
}
</style>
